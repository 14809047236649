.topHeader {
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px -4px !important;

  &__container {
    padding: 14px 12px;

    div[class*='MuiBox-root'] {
      display: flex;
      padding: 0 16px;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  &__logo {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    vertical-align: middle;
    min-width: 300px;
  }

  &__searchBox {
    max-width: 75%;
    width: 100%;
  }

  &__searchInput {
    background-color: transparent;
    border: 0 solid transparent !important;

    input {
      background-color: transparent;
      border: 0 solid transparent !important;
    }
  }

  &__userTools {
    align-items: center;
    margin-left: auto;

    .buttonTool {
      border-radius: 100%;
      margin: 0 12px;

      & > span {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
