/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.scrollbar {
  overflow: overlay;
}

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: rgba(128, 128, 128, 0.2);
  width: 16px;
  height: 16px;
  z-index: 999999;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(128, 128, 128, 0.2);
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.2);
  border-radius: 16px;
  border: 0;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* scrollbar when element is hovered */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  border: 4px solid #fff;
}

/* scrollbar when scrollbar is hovered */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

body {
  background-color: #373737 !important;
}

.break-word {
  word-wrap: break-word;
  width: 95%;
}

.cursor-pointer {
  cursor: pointer;
}
